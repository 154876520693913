import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error404 = () => {
    const { i18n, t } = useTranslation('translation');
    return (
            <div className="text-center p-5">
                <div className="display-1 text-200 fs-error">404</div>
                <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
                    {t('pageNotFound')}
                </p>
                <hr/>
                <p className="mb-3">
                    {t('makeSureEnteredCorrectAddress')}
                </p>
                <Link className="btn btn-sm btn-primary mt-3" to={"/"}>
                    <FontAwesomeIcon icon="home" className="mr-2"/>
                    {t('toMain')}
                </Link>
            </div>
    );
};

export default Error404;
