// AuthContext.js
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { authenticate, refresh, isExpired } from './userService';
import { v4 as uuidv4 } from 'uuid';
import Loading from "../../components/Loading";
import { Notification, toaster } from "rsuite";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

const getPayload = (jwt) => {
    return atob(jwt.split(".")[1]);
}

const AuthProvider = ({ children }) => {
    const { i18n, t } = useTranslation('translation');
    const [token, setToken_] = useState(localStorage.getItem("access_token"));
    
    if (!token) {
        let savedToken = localStorage.getItem("access_token");
        if (savedToken && !isExpired(savedToken)){
            setToken_(savedToken);
        }
    }

    const setToken = (newToken) => {
        setToken_(newToken);
        localStorage.setItem("access_token", newToken);
    };

    const contextValue = useMemo(
            () => ({
                token,
                setToken,
            }),
            [token]
    );
    
    return (
            <AuthContext.Provider value={{ contextValue }}>
                {children}
            </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export { AuthProvider, useAuth };
