import { createApi } from '@reduxjs/toolkit/query/react';
import { deleteBaseQuery, getBaseQuery, patchBaseQuery, postBaseQuery, setupBaseQuery } from './reducerService';

export const casesApi = createApi({
    reducerPath: 'casesApi',
    baseQuery: setupBaseQuery(`${process.env.REACT_APP_EXTERNAL_MANAGEMENT}`),
    tagTypes: ['Case'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getDocumentIdByNumberAndSurname: builder.query({
            query: ({ body, headers }) => postBaseQuery(body, '/search', headers),
        }),
        getDocumentById: builder.query({
            query: ({ id }) => getBaseQuery(`/${id}`),
        }),
        getCaseById: builder.query({
            query: ({ id }) => getBaseQuery(`/case/${id}`),
        }),
        getDamagedBaggageById: builder.query({
            query: ({ id }) => getBaseQuery(`/damagedbaggage/${id}`),
        }),
        getUnclaimedCaseDeliveryInfo: builder.query({
            query: ({ id }) => getBaseQuery(`unclaimedbaggage/${id}/deliveryflights`),
        }),
        getLostCaseDeliveryInfo: builder.query({
            query: ({ id, placeId }) => getBaseQuery(`lostbaggage/${id}/places/${placeId}/deliveryflights`)
        }),
    }),
});
export const {
    useLazyGetDocumentIdByNumberAndSurnameQuery,
    useGetDocumentByIdQuery,
    useLazyGetCaseByIdQuery,
    useLazyGetDamagedBaggageByIdQuery,
    useGetLostCaseDeliveryInfoQuery,
    useLazyGetLostCaseDeliveryInfoQuery,
    useGetUnclaimedCaseDeliveryInfoQuery,
    useLazyGetUnclaimedCaseDeliveryInfoQuery,
} = casesApi;
