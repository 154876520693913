import { Notification, toaster } from 'rsuite';
import store from '../../middleware/store';
import * as bson from 'bson';
import { v4 as uidv4 } from 'uuid';
import { getCurrentToken } from './userService';
import { AUTHORIZATION_HEADER } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';

const api = async (url, method = 'GET', body = null) => {
    const accessToken = getCurrentToken();
    const requestOptions = {
        method,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + accessToken,
        },
        body: method !== 'GET' && method !== 'DELETE' ? JSON.stringify(body) : null,
    };
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        if (response.status === 401) {
            //navigate('/');
            return;
        }
        let responseAsJson;
        try {
            responseAsJson = await response.json();
        } catch {
            try {
                responseAsJson = await response.text();
            } catch {
                responseAsJson = null;
            }
        }
        return responseAsJson ?? false;
    }
    try {
        const responseAsJson = await response.json();
        return responseAsJson;
    } catch {
        return null;
    }
};

export const _get = async (url) => {
    return await api(url);
};

export const _post = async (url, body) => {
    return await api(url, 'POST', body);
};

export const _delete = async (url) => {
    return await api(url, 'DELETE');
};

export const _put = async (url, body) => {
    return await api(url, 'PUT', body);
};

export const _patch = async (url, body) => {
    return await api(url, 'PATCH', body);
};

export const _getBLOB = async (url) => {
    const accessToken = getCurrentToken();
    const requestOptions = {
        method: 'GET',
        headers: {
            'X-BAGS-TOKEN': accessToken,
        },
    };
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        console.error(`Ошибка выполнения запроса: ${response.status} - ${response.statusText}`);
        return null;
    }
    return await response.blob();
};
