import { format, isValid, isToday, isYesterday, subDays, startOfDay, endOfDay } from 'date-fns';
import { invert } from 'lodash';
import { Tooltip, Whisper } from 'rsuite';

/**
 * @param {object} object
 * @returns boolean
 */
export const objectIsEmpty = (object) => IsNullOrUndefined(object) || !Object.keys(object)?.length;
/**
 * @param {object} object
 * @returns boolean
 */
export const objectIsNotEmpty = (object) => !objectIsEmpty(object);
export const formatDateTime = (date) => (date ? format(new Date(date), 'dd.MM.yyyy HH:mm:ss') : '');
export const formatDate = (date) => (date ? format(new Date(date), 'dd.MM.yyyy') : '');
export const formatTime = (date) => (date ? format(new Date(date), 'HH:mm:ss') : '');
export const isGivenDateIsToday = (date) => isToday(date);
export const isGivenDateIsYesterday = (date) => isYesterday(date);
export const toISODateOnlyString = (date) =>
    date !== null ? format(new Date(date), 'yyyy-MM-dd 00:00:00') + 'Z' : null;
export const toLocalDateTimeString = (date) => (date !== null ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : null);
const formatters = {
    number: (value) => value,
    string: (value) => value,
    date: (value) => new Date(value),
    caseStatus: (value) => value,
};
const comparators = {
    /**
     *
     * @param {Date[]} values
     * @returns
     */
    date: (values) => {
        if (!isValid(values[0])) {
            return -1;
        }
        return values[0] - values[1];
    },
    /**
     * @param {number[]} values
     * @returns boolean
     */
    number: (values) => values[0] - values[1],
    /**
     * @param {string[]} values
     * @returns boolean
     */
    string: (values, { stringOptions = {} }) => values[0]?.localeCompare(values[1], stringOptions?.locales ?? []),
    caseStatus: (values) => values[0].id - values[1].id,
};
/**
 *
 * @typedef {Object} Options
 * @property {string} sortType 'asc' | 'desc'
 * @property {string} columType
 *
 * @param {string | number} a
 * @param {string | number} b
 * @param {Options} options
 * @returns
 */
export function compare(a, b, options) {
    const { sortType = 'desc', columType = 'string' } = options;
    const formattedA = formatters[columType]?.(a);
    const formattedB = formatters[columType]?.(b);
    const values = sortType === 'desc' ? [formattedB, formattedA] : [formattedA, formattedB];
    const comparator = comparators[columType];

    return comparator?.(values, options);
}
export const checkIsLoading = (queries, checkIsRefetch = true) =>
    queries?.find((query) => (checkIsRefetch ? query.isLoading || query.isFetching : query.isLoading));
export const isValidationHasError = (error) => {
    if (error?.hasError !== undefined && error?.hasError !== null) {
        return error.hasError;
    }
    return error !== undefined;
};
/**
 *
 * @param {number} bytes
 * @param {number} roundTo
 * @returns {number}
 */
export const bytesToMegaBytes = (bytes, roundTo = 2) =>
    roundTo ? Number((bytes / (1024 * 1024)).toFixed(roundTo)) : bytes / (1024 * 1024);
export const IsNullOrUndefined = (value) => value === undefined || value === null;
export const notNullOrUndefined = (value) => !IsNullOrUndefined(value);
/**
 *
 * @type {function(): {from: string, to: string}}
 */

export const getDefaultFiltersDateRange = () => {
    const from = subDays(startOfDay(new Date()), 29).toISOString();
    const to = endOfDay(new Date()).toISOString();
    return { to, from };
};

export const getDefaultSearchFormDateRange = () => {
    const createdFrom = subDays(startOfDay(new Date()), 29).toISOString();
    const createdTo = endOfDay(new Date()).toISOString();
    return { createdFrom, createdTo };
};

export const getPassedDaysAmount = (from, to) => {
    const one_day = 1000 * 60 * 60 * 24;
    const diff = to.getTime() - from.getTime();
    const totalDays = Math.abs(Math.ceil(diff / one_day));
    return totalDays;
};

/**
 *
 * @param {number} start
 * @param {number} stop
 * @param {number} step
 * @returns {Array<number>}
 */
export const getRangeOfYears = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
/**
 * @example
 * var arr = new Array(7);
 * arr.includes() -> true
 *
 * var arr2 = new [{value: 1}];
 * arr2.includes() -> false
 *
 * var arr3 = new Array(7);
 * arr3.push({value: 1}) -> [empty x 7, {value: 1}]
 * arr3.includes() -> true
 */

export const isNotEmptyArray = (array) => Array.isArray(array) && !!array.length;
export const emptyValueInArray = (array) => {
    return array?.includes();
};
export const addDynamicPaths = (length, tabName, itemName) =>
    Array(length)
        .fill(0)
        .map((item, ind) => {
            return `${tabName}[${ind}].${itemName}`;
        });

export const flightNumberRule = (value) => /-[0-9]{2,4}$/.test(value);

export const isNotValidArrayForm = (validationErrors) =>
    validationErrors && !!Object.values(validationErrors).filter((errors) => errors && objectIsNotEmpty(errors)).length;

export const validationErrorsFromValidationResult = (validationResult) =>
    Object.entries(validationResult).reduce((acc, [key, value]) => {
        if (value?.hasError && value?.errorMessage) {
            return { ...acc, [key]: value.errorMessage };
        }

        return { ...acc };
    }, {});

export const validateBySchema = (validationSchema) => (data) =>
    validationErrorsFromValidationResult(validationSchema.check(data));

export const enumFromObject = (object) => Object.freeze({ ...object, ...invert(object) });
export const FaSvgIcon = ({ faIcon, iconText, ...rest }) => {
    const { width, height, svgPathData } = faIcon;
    return (
        <Whisper placement="topStart" trigger="hover" speaker={<Tooltip>{iconText}</Tooltip>}>
            <svg {...rest} viewBox={`0 0 ${width} ${height}`}>
                <path d={svgPathData} />
            </svg>
        </Whisper>
    );
};

export const sortIataIcao = (keyword) => {
    const compareMatch = (val1, val2) => {
        if (val1 && !val2) {
            return -1;
        }
        if (val2 && !val1) {
            return 1;
        }
        return 0;
    };

    const compareStrings = (a, b, keyword) => {
        a = a ? a.toLowerCase() : '';
        b = b ? b.toLowerCase() : '';
        keyword = keyword ? keyword.toLowerCase() : '';
        const aMatch = a.includes(keyword);
        const bMatch = b.includes(keyword);

        if (aMatch && bMatch) {
            const aIndex = a.indexOf(keyword);
            const bIndex = b.indexOf(keyword);
            if (aIndex === bIndex) {
                return a < b ? -1 : a > b ? 1 : 0;
            }
            return aIndex - bIndex;
        }
        return compareMatch(aMatch, bMatch);
    };

    return (a, b) => {
        let res = compareStrings(a.iata, b.iata, keyword);
        if (res !== 0) {
            return res;
        }

        res = compareStrings(a.label, b.label, keyword);
        if (res !== 0) {
            return res;
        }

        res = compareStrings(a.icao, b.icao, keyword);
        return res;
    };
};

export const nameIataIcaoFilter = (val, _, item) => {
    if (!val) {
        return false;
    }
    const { name, iata, icao, label } = item;
    return `${label} ${name} ${iata} ${icao}`.toLowerCase().includes(val.toLowerCase());
};

export const getDocumentNumber = (documentData) => {
    return documentData?.caseNumber || documentData?.quickDeliveryNumber;
};
export const getErrorsFromValidationResult = (validationResult) =>
    Object.entries(validationResult).reduce((acc, [key, value]) => {
        if (value?.hasError && value?.errorMessage) {
            return { ...acc, [key]: value.errorMessage };
        }

        return { ...acc };
    }, {});

export const handleReload = () => {
    window.location.reload();
};

export const isHeicFile = (file) => {
    const heicFileExtensions = ['.heic', '.heif'];
    const normalizedName = file?.name?.toLowerCase();
    const checkResult = heicFileExtensions.some((extension) => normalizedName?.endsWith(extension));
    return checkResult;
};

export const rusWordsDeclension = (total, optionsArray) => {
    total = Math.abs(total) % 100;
    let num = total % 10;
    if (total > 10 && total < 20) return optionsArray[2];
    if (num > 1 && num < 5) return optionsArray[1];
    if (num === 1) return optionsArray[0];
    return optionsArray[2];
};

export const engWordsDeclension = (total, optionsArray) => {
    total = Math.abs(total) % 100;
    let num = total % 10;
    if (num > 1) return optionsArray[1];
    return optionsArray[0];
};
