const storageKey = 'i18nextLang';
const supportedLangs = ['en', 'ru'];
const defaultLang = 'ru';

const isSupportedLang = (lang) => supportedLangs.includes(lang)

const languageDetector = {
    type: 'languageDetector',
    async: true,
    detect: async (callback) => {
        let lang = localStorage.getItem(storageKey);

        if (!lang) {
            lang = navigator.language.split('-')[0];
            lang = isSupportedLang(lang) ? lang : defaultLang;
            localStorage.setItem(storageKey, lang);
        }

        callback(lang);
    },
    init: () => {},
    cacheUserLanguage: (lang) => {
        localStorage.setItem(storageKey, lang);
    },
};

export default languageDetector;
