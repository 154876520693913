import { createRoot } from 'react-dom/client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import AppRoot from "./AppRoot";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/open-sans';
import './config/i18n';

createRoot(document.getElementById('root')).render(<AppRoot />);
