// noinspection NpmUsedModulesInstalled
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { casesApi } from './reducers/casesApi';
import { supplementaryInfoApi } from "./reducers/supplementaryInfoApi";
import { documentsLinksApi } from "./reducers/documentsLinksApi";
import { messagesApiService } from "./services";
import { MESSAGES_FEATURE_NAME, MessagesReducer } from './reducers';
import { SUPPLEMENTARY_FEATURE_NAME, SupplementaryReducer } from './reducers/Supplementary';

const createRootReducer = () =>
    combineReducers({
        [casesApi.reducerPath]: casesApi.reducer,
        [supplementaryInfoApi.reducerPath]: supplementaryInfoApi.reducer,
        [documentsLinksApi.reducerPath]: documentsLinksApi.reducer,
        [messagesApiService.reducerPath]: messagesApiService.reducer,
        [messagesApiService.reducerPath]: messagesApiService.reducer,
        [MESSAGES_FEATURE_NAME]: MessagesReducer,
        [SUPPLEMENTARY_FEATURE_NAME]: SupplementaryReducer,
    });

const isDevelopment = process.env.NODE_ENV === 'development';
/// Add RTK query to middleware, when it is needed to turn on RTK features
/// such as caching, prefetching, polling and
/// OTHER RTK Query features.
function configureMainStore(preloadedState) {
    return configureStore({
        reducer: createRootReducer(),
        middleware: (getDefaultMiddleware) => 
                getDefaultMiddleware().concat(
                        casesApi.middleware,
                        supplementaryInfoApi.middleware,
                        documentsLinksApi.middleware,
                        messagesApiService.middleware,
                ),
        devTools: isDevelopment,
        preloadedState: preloadedState,
    });
}

const store = configureMainStore();
export default store;
