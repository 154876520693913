import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import isEmpty from 'lodash/isEmpty';
import i18n from "../../config/i18n";
import { getCurrentToken } from '../services/userService';
import { AUTHORIZATION_HEADER } from "../../constants/constants";
import { useNavigate } from "react-router-dom";

export const setupBaseQuery = (baseUrl) => {
    const authenticatedBaseQuery = fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('X-Language', `${i18n.language.toUpperCase()}`);
            const accessToken = getCurrentToken();
            if (!isEmpty(accessToken)) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
            return headers;
        },
    });
    return async (args, api, extraOptions) => {
        const result = await authenticatedBaseQuery(args, api, extraOptions);
        return result;
    };
};
const baseQuery = (body, url, method, headers) => {
    return {
        url: url,
        method: method,
        body: body ?? {},
        headers: headers
    };
};
export const postBaseQuery = (body, url, headers) => {
    return baseQuery(body, url, 'POST', headers);
};
export const putBaseQuery = (body, url) => {
    return baseQuery(body, url, 'PUT');
};
export const getBaseQuery = (url) => url;
export const patchBaseQuery = (body, url) => {
    return baseQuery(body, url, 'PATCH');
};
export const deleteBaseQuery = (url, body = {}) => {
    return baseQuery(body, url, 'DELETE');
};

export const getBSON = (url) => {
    return {
        url,
        method: 'GET',
        headers: {
            Accept: 'application/bson',
            'content-type': 'application/bson',
        },
    };
};
