import { createApi } from '@reduxjs/toolkit/query/react';
import { deleteBaseQuery, getBaseQuery, patchBaseQuery, postBaseQuery, setupBaseQuery } from './reducerService';

export const supplementaryInfoApi = createApi({
    reducerPath: 'supplementaryInfoApi',
    baseQuery: setupBaseQuery(`${process.env.REACT_APP_EXTERNAL_MANAGEMENT}`),
    tagTypes: ['SupplementaryInfo'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getDelayCodesList: builder.query({
            query: ({ lang }) => `/delaycodes/dropdown/${lang}`,
        }),
        getMessageTypes: builder.query({
            query: () => `/message_types`,
        }),
        getDamageReasons: builder.query({
            query: ({ lang }) => `/damage_reason/dropdown/${lang}`,
        }),
        getCurrencyList: builder.query({
            query: ({ lang }) => `/currencies/dropdown/${lang}`,
        })
    }),
});
export const {
    useGetDelayCodesListQuery,
    useLazyGetMessageTypesQuery,
    useGetDamageReasonsQuery,
    useGetCurrencyListQuery
} = supplementaryInfoApi;
    
