import { useEffect } from 'react';
import { useAuth } from "middleware/services/AuthContext";
import { useDispatch } from 'react-redux';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { windowResized } from 'middleware/reducers/uiSlice';
import { MessagesApiMethods } from '../middleware/services';
import { MessagesActions } from '../middleware/reducers';
import { MessageGroups } from '../middleware/models';

var signalConnection = null;
const hub = `${process.env.REACT_APP_MESSAGING}/hubs/message`;
export default function SignalConnection() {
    const authState = useAuth();
    const dispatch = useDispatch();
    const { token } = authState;
    const [callOrganizationChannel] = MessagesApiMethods.useCallOrganizationChannelMutation();
    useEffect(() => {
        window.onresize = () => dispatch(windowResized());
        if (!signalConnection && token) {
            signalConnection = new HubConnectionBuilder()
                .withUrl(hub, {
                    accessTokenFactory: () => token,
                    UseDefaultCredentials: true,
                })
                .withAutomaticReconnect()
                .build();
            if (signalConnection) {
                signalConnection
                    .start()
                    .then(() => {
                        signalConnection.on('ReceiveMessage', (message) => {
                            const messages = JSON.parse(message);
                            const allMessages = messages.find((x) => x.group.id === MessageGroups.All);
                            dispatch(MessagesActions.setAllMessagesCount(allMessages.count));
                            dispatch(MessagesActions.setGroups(messages));
                        });
                        callOrganizationChannel();
                    })
                    .catch((e) => console.log('Connection failed: ', e));
            }
        }
        return () => {
            window.onresize = null;
        };
    }, [dispatch, token, signalConnection]);
    return null;
}
