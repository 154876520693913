import isNil from 'lodash/isNil';

export const FOOTER_HEIGHT = 51;
export const ENTER_CHAR_CODE = 13;
export const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// prettier-ignore
export const PHONE_PATTERN = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
export const RU_MASK = '+7 (999) 999-99-99';
export const BLR_MASK = '+375 (99) 999-99-99';
export const DEFAULT_MASK = '';
export const onlyLettersPattern = /^[a-zA-Z]+$/;
export const onlyNumbersAndLettersPattern = /^[a-zA-Z]+$/;
export const initialsPattern = /^[A-Za-z]{2,3}$/;
// eslint-disable-next-line
export const withoutWithoutCyrillicSymbolsPattern = /^[A-Za-z0-9\-!@#$%^&*]*$/;
// prettier-ignore
// eslint-disable-next-line
export const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const flightNumberPattern = /-[0-9]{2,4}$/;
export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
export const tagNumberMask = 'CCYYYYYY';
export const tagNumberMaskOptions = {
    blocks: {
        CC: {
            mask: /[a-z-A-Z0-9]$/,
        },
        Y: {
            mask: /[x-xX-X0-9]$/,
        },
    },
    validate: (value) => value?.length <= 8,
};
export const ORGANIZATION_TYPE = {
    airport: 'airports',
    carrier: 'carriers',
    organization: 'organization',
    airports: 'airports',
    carriers: 'carriers',
    externalAirport: 'externalAirports',
    externalAirports: 'externalAirports',
    externalCarrier: 'externalCarriers',
    externalCarriers: 'externalCarriers',
    externals: 'externals',
    airportUser: 'airports',
    aircompanyUser: 'carriers',
    1: 'airports',
    2: 'carriers',
    4: "externalAirports",
    5: "externalCarriers",
    all: 'organization',
};
export const USER_ROLES = {
    arAdmin: 'arAdmin',
    orgAdmin: 'orgAdmin',
    airportUser: 'airportUser',
    airCompanyUser: 'aircompanyUser',
};
export const CASE_TYPE_ENUM = {
    0: 'lostbaggage',
    1: 'unclaimedbaggage',
    2: 'damagedbaggage',
    5: 'quickDelivery',
    6: 'commercialAct',
    7: 'deliveryOrder',
    lostbaggage: 0,
    unclaimedbaggage: 1,
    damagedbaggage: 2,
    quickDelivery: 5,
    commercialAct: 6,
    deliveryOrder: 7,
    all: 'all',
};
export const LOST_STATUS_ENUM = {
    Suspended: 3,
    Issued_To_Passenger: 4,
    Archived: 2,
};
export const LOST_BAGGAGE_STATUS_ENUM = {
    In_search: 0,
    Suspended: 1,
    Completed: 3,
    Archived: 2,
    Deleted: 5,
};
export const LOST_BAGGAGE_EDITABLE_CASE_NUMBER_STATUSES = [
    LOST_BAGGAGE_STATUS_ENUM.In_search,
    LOST_BAGGAGE_STATUS_ENUM.Suspended,
];
export const UNCLAIMED_STATUS_ENUM = {
    Suspended: 1,
    Unclaimed: 6,
    In_Delivery: 4,
    Delivered: 7,
    Archived: 2,
    Deleted: 5,
    Completed: 3,
};
export const UNCLAIMED_BAGGAGE_EDITABLE_CASE_NUMBER_STATUSES = [
    UNCLAIMED_STATUS_ENUM.Suspended,
    UNCLAIMED_STATUS_ENUM.In_Delivery,
    UNCLAIMED_STATUS_ENUM.Unclaimed,
];
export const DAMAGED_BAGGAGE_STATUS_ENUM = {
    Created: 0,
    Archived: 1,
    Deleted: 2,
};
export const DAMAGED_BAGGAGE_EDITABLE_CASE_NUMBER_STATUSES = [DAMAGED_BAGGAGE_STATUS_ENUM.Created];
export const BAGGAGE_PLACE_STATUS_ENUM = {
    Active: 1,
    AwaitingDeliveryConfirmation: 2,
    Suspended: 3,
    Delivered: 4,
};
export const MAX_ALLOWED_TRANSFER_ROUTES = 5;
export const MAX_ALLOWED_UNCLAIMED_BAGGAGE_PLACES = 10;
export const MAX_ALLOWED_DAMAGED_BAGGAGE_PHOTO = 6;
export const MAX_ALLOWED_DAMAGED_BAGGAGE_PLACES = 10;
export const MAX_ALLOWED_DAMAGE_POINTS = 10;
export const MAX_ALLOWED_DAMAGE_PHOTO_SIZE_MB = 10;
export const MAX_ZOOM = 2;
export const MIN_ZOOM = 1;
export const DEFAULT_ZOOM = 0;
export const MAX_LENGHT_FOR_RUSSIAN_NUMBERS = 11;
export const MAX_LENGTH_FOR_INTERNATIONAL_NUMBERS = 9;
export const DEFAULT_PHONE_PLACEHOLDER = '+7 (123) 456-78-90';
export const COLOR_CODES_FOR_DESCRIPTION = ['MC', 'PR'];
export const BAGGAGE_TYPE_CODES_FOR_DESCRIPTION = ['99'];
export const DELIVERY_BAGGAGE_FORM_UPPERCASE_FIELDS = ['deliveryTagNumber'];
export const MAX_COMMENT_CHARS = 5000;
export const MAX_BAGGAGE_TAG_DISPLAY = 2;
export const MAX_FLIGHT_NUMBER_DISPLAY = 1;
export const KEYBOARD_KEYS = {
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    BACKSPACE: 'Backspace',
};
export const ARCHIVED = 2;
export const isNullOrEmpty = (value) => isNil(value) || value.toString() === '';
export const baggageTagNumberValidation = (value) => {
    if (isNullOrEmpty(value)) return true;
    const correctNumberOfCharacters = value.length === 8;
    const companyCodeValid = /^[a-zA-Z0-9]{2}/.test(value);
    const tagNumber = value.slice(2, 8);
    const onlyNumberAndX = /^[0-9,xX]{6}/.test(tagNumber);
    const numberOfXLessThan3 = (tagNumber.match(/[xX]/g) || []).length < 3;
    return correctNumberOfCharacters && companyCodeValid && onlyNumberAndX && numberOfXLessThan3;
};
export const shippingTagNumberValidation = (value) => {
    if (isNullOrEmpty(value)) return true;

    const firstPart = value.slice(0, 2);
    const secondPart = value.slice(2, 8);

    const onlyNumbersAndLettersInFirstPart = /^[a-zA-Z0-9]{2}$/.test(firstPart);
    const onlyNumberrsInSecondPart =  /^[0-9]{6}$/.test(secondPart);
    const correctNumberOfCharacters = value.length === 8;

    return correctNumberOfCharacters && onlyNumbersAndLettersInFirstPart && onlyNumberrsInSecondPart;
}
export const IS_DEV_ENV = process.env.NODE_ENV !== 'production';

export const currencySymbolByISOCode = {
    USD: '$',
    EUR: '€',
    RUB: '₽',
};
export const baggageTagNumberWithDashValidation = (value) => {
    if (value != null && value === '-') return true;
    return baggageTagNumberValidation(value);
};
export const MINIMAL_IMAGE_SIZE = 80;
export const LUGGAGE_CONTENT_INITIAL_ROWS_COUNT = 7;
export const LUGGAGE_CONTENT_ROWS_MAX_COUNT = 50;
export const LUGGAGE_CONTENT_ROWS_WITCH_PLACEMENT_TOP = 5;
export const MAX_ALLOWED_IMAGE_SIZE_MB = 10;
export const DEFAULT_CURRENCY_CODE_BY_LANG = {
    ru: 'RUB',
    en: 'USD',
};
export const MAX_DYNAMIC_PATHS = 10;

export const WITH_ZIPPED_SPECIAL_CODES = ['DD', 'DL', 'DM', 'DR', 'DT', 'DX', 'RD', 'RL', 'RM', 'RR', 'RT', 'RX'];
export const BAGGAGE_FEATURES_PLACEHOLDER = '00-Y-CCCCCC';
export const SPECIAL_CODE_MASK = '00a-a-aaaaaa';
export const COMMON_CODE_MASK = '00-a-aaaaaa';
export const Pages = {
    Messages: 0,
};
export const NON_APPLICABLE_PERMISSIONS_ENUM = {
    createSearchThingCase: 4,
    manualQuickDeliveryCaseSearch: 512,
    createQuickDeliveryCase: 1024,
    createFoundThingCase: 2048,
    createQuickDeliveryNotification: 16384,
    extendedEditDamagedBaggageCase: 131072,
    extendedEditLostThingCase: 262144,
    extendedEditFoundThingCase: 524288,
    extendedEditQuickDeliveryCase: 1048576,
};
export const DELIVERY_BAGGAGE_ROUTES_ENUM = {
    resendRequest: 'request_a_resend',
};
export const SYSTEM_ADMIN_ORGANIZATION_TYPE = {
    1: 'Airport',
    2: 'Carrier',
    4: 'ExternalAirport',
    5: 'ExternalCarrier'
};
export const UNKNOWN_COMPANY = '00000000-0000-0000-0000-000000000000';

export const QUICK_DELIVERY_STATUS_ENUM = {
    ReadyForDelivery: 1,
    InDelivery: 2,
    Delivered: 3,
    Archived: 4,
    Deleted: 5,
    Issued: 6,
};

export const EXTENDED_USER_ROLES = {
    isAdmin: 'isAdmin',
    isAirCompanyUser: 'isAirCompanyUser',
    isAirPortAdmin: 'isAirPortAdmin',
    isAirportUser: 'isAirportUser',
    isCarrierAdmin: 'isCarrierAdmin',
};

export const THING_CASES_ENUM = {
    lostThing: 1,
    foundThing: 2,
};

export const WEEK_DAYS = {
    0: 'monday',
    1: 'tuesday',
    2: 'wednesday',
    3: 'thursday',
    4: 'friday',
    5: 'saturday',
    6: 'sunday',
};

export const MOBILE_OS_LIST = ['Androind', 'iPhone'];

export const DEVICE_TYPES_ENUM = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop',
};

export const PHOTO_PACKAGE_TYPES_ENUM = {
    Baggage: 0,
    DamagedBaggage: 1,
    FoundThing: 2,
};

export const FORM_MODE_ENUM = {
    CREATE: 'create',
    VIEW: 'view',
    EDIT: 'edit',
};

// BEGIN OF ARMW-480
export const initialPassengerNotifications = {
    overrideDocumentSettings: false,
    byEmail: true,
    bySMS: true,
};
// END OF ARMW-480
export const LANGUAGES_ENUM = {
    RU: 'ru',
    EN: 'en',
};
export const bytesToRealFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024,
            dm = 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const DATE_FORMAT_ENUM = {
    RU: "ДД.ММ.ГГГГ",
    EN: "DD.MM.YYYY"
}

export const ONE_MONTH_DAYS = 29

export const DATE_RANGE_DAYS_LIMIT = 91;
export const BAGGAGE_FEATURES_ENTRY_TYPES = {
    withZipper: 2,
    withoutZipper: 3,
    variousElements: 7,
};

// ARMW-613
export const BAGGAGE_ISSUE_TYPES = {
    IssuedAtTheAirport: 0,
    DeliveredToPassenger: 1,
    SentToAnotherAirport: 2,
};

// --- Linked Documents ---
export const LINK_TYPE_ENUM = {
    0: 'manualLink',
    1: 'createdUsingDocument',
    2: 'copiedFromDocument',
    3: 'deliveryRequest',
    4: 'delivery',
    manualLink: 0,
    createdUsingDocument: 1,
    copiedFromDocument: 2,
    deliveryRequest: 3,
    delivery: 4,
};
export const DOCUMENT_NUMBER_PLACEHOLDER = 'XXX-AA-000000';

export const LINK_OPTIONS_ENUM = {
    manualLink_DamagedBaggageWithLostBaggage: 'manualLink_DamagedBaggageWithLostBaggage',
    createdLink_DamagedBaggageWithLostBaggage: 'createdLink_DamagedBaggageWithLostBaggage',
};
export const SENT_TO_ANOTHER_AIRPORT_ISSUE_OPTION = 2;
// ^^^ Linked Documents ^^^

export const MIN_SWIPE_DISTANCE = 50;

export const COMMERCIAL_ACT_DOCUMENT_BASE_ENUM = {
    damagedBaggage: 1,
    unclaimedBaggage: 2,
};

export const NO_DATA = '---';

export const MAX_TAG_TO_DISPLAY = 2;

export const COMMENTARY_LENGTH  = {
    BAGGAGE_PLACE: 1500,
    ADDITIONAL_SETTINGS: 5000,
    THINGS: 5000,
    QUICK_DELIVERY: 5000,
    STORAGE_LOCATION: 512
};

export const COLOR_DESCRIPTION_LENGTH = {
    CASE: 300,
    UNCLAIMED_BAGGAGE: 300,
    DAMAGED_BAGGAGE: 300,
};
export const BAGGAGE_TYPE_DESCRIPTION_LENGTH = {
    CASE: 300,
    UNCLAIMED_BAGGAGE: 300,
    DAMAGED_BAGGAGE: 300,
};
export const ADDRESS_MAX_LENGTH = 256;
export const PERSONAL_INFO_LENGTH = {
    NAME: 30,
    SURNAME: 30,
    PATRONYMIC: 30,
    INITIALS: 3,
};
export const STORE_PLACE_MAX_LENGTH = 300;
export const THING_DESCRIPTION_MAX_LENGTH = 300;
export const BAGGAGE_CONTENT_LENGTH = {
    SUBJECT: 64,
    DESCRIPTION: 300
};
export const COST_MAX_NUMBERS_INCLUDE_SPACES = 16;
export const MIN_WEIGHT = 1.00;
export const MAX_WEIGHT = 99999.99;
export const COMMERCIAL_ACT_MAX_REGISTERED_PLACES = 50;
export const COMMERCIAL_ACT_MAX_DAMAGED_PLACES = 50;
export const COMMERCIAL_ACT_MAX_PLACES_RECIEVED = 50;

export const MAX_DECIMAL_NUMBER = 79228162514264337593543950335;
export const MAX_COST = 1000000000;

export const CASE_NUMBER_INPUT_MASK = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, '-', /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
