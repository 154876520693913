import isEmpty from "lodash/isEmpty";
import { AUTHORIZATION_HEADER } from "../../constants/constants";

const getPayload = (jwt) => {
    return atob(jwt.split(".")[1]);
}

export const getCurrentToken = () => {
    let token = localStorage.getItem("access_token");
    if (isEmpty(token)) {
        token = sessionStorage.getItem("access_token");
        if (!isEmpty(token)) {
            localStorage.setItem("access_token", token);
        }
    }
    return token;
};

export const saveToken = (token) => {
    localStorage.setItem("access_token", token);
    sessionStorage.setItem("access_token", token);
}

export const isExpired = (token) => {
    const payload = JSON.parse(getPayload(token));
    const expiration = new Date(0);
    expiration.setUTCSeconds(payload.exp);
    const now = new Date();
    if (now.getTime() > expiration.getTime()){
        return true;
    }

    return false;
}

export const authenticate = async (guid) => {
    try {
        const response = await fetch(process.env.REACT_APP_PASSENGER_DOCS_SEARCH + '/token/' + guid);
        const data = await response.json();
        return data.token;
    } catch (error) {
        console.error('Authentication error:', error);
        throw error;
    }
};

export const refresh = async (token) => {
    try{
        const options = {
            headers: new Headers({'X-BAGS-TOKEN': 'Bearer ' + token})
        }
        const response = await fetch(process.env.REACT_APP_PASSENGER_DOCS_SEARCH + '/token/refresh', options);
        const data = await response.json();
        return data.token;
    } catch (error) {
        console.error('Authentication error:', error);
        throw error;
    }
};
