import {createSlice} from "@reduxjs/toolkit";
import {FOOTER_HEIGHT} from "../../constants/constants";

export const uiReducerName = "ui";

const initialState = {
    windowHeight: window.innerHeight - FOOTER_HEIGHT,
    windowWidth: window.innerWidth,
    alertShown: false,
    alertTitle: null,
    alertMessage: null,
    header: {profiles: true, dates: true, connections: true}
};

const uiSlice = createSlice({
    name: uiReducerName,
    initialState,
    reducers: {
        windowResized: (state) => {
            return {
                ...state,
                windowHeight: window.innerHeight - FOOTER_HEIGHT,
                windowWidth: window.innerWidth,
            };
        },
        showAlertDialog: (state, action) => {
            return {
                ...state,
                alertShown: true,
                alertTitle: action.payload.title,
                alertMessage: action.payload.message,
            };
        },
        hideAlertDialog: (state) => {
            return {
                ...state,
                alertShown: false,
                alertTitle: null,
                alertMessage: null,
            };
        },
        resetUIData: () => {
            return {...initialState};
        },
        setHeaderDropdowns: (state, action) => {
            return {
                ...state,
                header: action.payload
            };
        },
    },
});

export const {
    windowResized,
    showAlertDialog,
    hideAlertDialog,
    resetUIData,
    setHeaderDropdowns,
} = uiSlice.actions;

export const uiSelector = (state) => state.ui;

export default uiSlice.reducer;
