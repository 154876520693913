import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import languageDetector from '../lib/languageDetector';
import resourcesToBackend from 'i18next-resources-to-backend';

i18n.use(languageDetector)
        .use(initReactI18next)
        .use(ChainedBackend)
        .init({
            fallbackLng: 'ru',
            debug: false,
            interpolation: {
                escapeValue: false,
            },
            backend: {
                backends: [
                    HttpBackend,
                    resourcesToBackend((lng, ns, clb) => {
                        import(`../${ns}/locales/${lng}.json`)
                                .then((resources) => {
                                    clb(null, resources);
                                })
                                .catch((error) => {
                                    clb(error);
                                });
                    }),
                ],
                backendOptions: [
                    {
                        loadPath: '/locales/{{lng}}/{{ns}}.json',
                    },
                ],
            },
        });

export default i18n;
