import { Navigate, Route, Routes } from 'react-router-dom';
import React, { lazy } from 'react';
import MainLayout from './components/Layouts/mainLayout';
import logo from './logo.svg';
import ErrorLayout from "./components/Layouts/ErrorLayout";
import SignalConnection from 'components/SignalConnection';
const StartPage = lazy(() => import('components/StartPage/StartPage'));

function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="/errors" element={<ErrorLayout />} />
                <Route path="/:caseType/:caseId" element={<MainLayout />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <SignalConnection />
        </>
    );
}

export default App;
