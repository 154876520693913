import { atom, selectorFamily, selector } from 'recoil';
import { _getBSON, _get, _post, _put, _delete, _patch, _getBLOB } from "../../../middleware/services/apiService";

export const formModeAtom = atom({
    key: 'formModeAtom',
    default: 'default', // default | disabled | edit
});

export const languageAtom = atom({
    key: 'languageAtom',
    default: 'ru',
});

export const apiServices = Object.freeze({
    EXTERNAL_MANAGEMENT: process.env.REACT_APP_EXTERNAL_MANAGEMENT
});

export const airportsDataSelector = selector({
    key: 'airportsDataSelector',
    default: [], 
    get: async ({ get }) => {
        const language = get(languageAtom);
        const json = await apiRequest(apiServices.EXTERNAL_MANAGEMENT, `airports/dropdown/${language}`);
        return json.map(v => ({ iata: v.iata, icao: v.icao, code: v.code, label: `${v.iata || v.icao} / ${v.name}`, value: v.id }));
    }
})

export function apiRequest(service, endpoint, body, method) {
    const url = `${service}/${endpoint}`;
    if (method === 'PUT' && body) {
        return _put(url, body);
    }
    if (method === 'DELETE') {
        return _delete(url);
    }
    if (method === 'PATCH' && body) {
        return _patch(url, body);
    }
    return body ? _post(url, body) : _get(url);
}

