import { MESSAGES_FEATURE_NAME } from './reducer';

export class MessagesSelector {
    static selectMode(state) {
        return state[MESSAGES_FEATURE_NAME].messageMode;
    }
    static selectId(state) {
        return state[MESSAGES_FEATURE_NAME].messageId;
    }
    static selectList(state) {
        return state[MESSAGES_FEATURE_NAME].messageList;
    }
    static selectThread(state) {
        return state[MESSAGES_FEATURE_NAME].threadMessages;
    }
    static selectMessageListIsLoading(state) {
        return state[MESSAGES_FEATURE_NAME].messageListIsLoading;
    }
    static selectInheritanceMode(state) {
        return state[MESSAGES_FEATURE_NAME].inheritanceMode;
    }
    static selectNeedData(state) {
        return state[MESSAGES_FEATURE_NAME].needData;
    }
    static selectSearchTerm(state) {
        return state[MESSAGES_FEATURE_NAME].searchTerm;
    }
    static selectSkip(state) {
        return state[MESSAGES_FEATURE_NAME].skip;
    }
    static selectIsLatest(state) {
        return state[MESSAGES_FEATURE_NAME].isLatest;
    }
    static selectSelectedGroups(state) {
        return state[MESSAGES_FEATURE_NAME].selectedGroups;
    }
    static selectGroups(state) {
        return state[MESSAGES_FEATURE_NAME].groups;
    }
    static selectAllMessagesCount(state) {
        return state[MESSAGES_FEATURE_NAME].allMessagesCount;
    }
    static selectRestrictedOrganization(state) {
        return state[MESSAGES_FEATURE_NAME].restrictedOrganization;
    }
    static selectIsMaintainable(state) {
        return state[MESSAGES_FEATURE_NAME].isMaintainable;
    }
    static selectIsHiddenExist(state) {
        return state[MESSAGES_FEATURE_NAME].isHiddenExist;
    }
    static selectShowHidden(state) {
        return state[MESSAGES_FEATURE_NAME].showHidden;
    }
}
