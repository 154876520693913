import { MessageGroups, MessageModes } from '../../models';

export const messagesInitialState = {
    messageMode: MessageModes.Thread,
    messageId: null,
    messageList: null,
    messageListIsLoading: false,
    threadMessages: [],
    inheritanceMode: null,
    skip: 0,
    needData: false,
    isLatest: false,
    groups: [],
    selectedGroups: [MessageGroups.All],
    allMessagesCount: 0,
    searchTerm: undefined,
    restrictedOrganizationId: undefined,
    isHiddenExist: false,
    showHidden: false,
    isMaintainable: undefined,
};
