import PropTypes from 'prop-types';
import {Navigate, Route, Routes} from "react-router-dom";
import {Container, Content, FlexboxGrid, Header, Navbar, Panel} from 'rsuite';
import Error404 from '../Errors/Error404';
import './ErrorLayout.less';

const ErrorLayout = ({match: {url}}) => {
    return (
            <Container>
                <Content>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={12}>
                            <Panel className="error-panel" bordered bodyFill shaded>
                                <Routes>
                                    <Route path={`${url}/404`} element={<Error404/>}/>
                                    {/*Redirect*/}
                                    <Route element={<Navigate to={`${url}/404`}/>}/>
                                </Routes>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </Container>
    );
};

ErrorLayout.propTypes = {match: PropTypes.object};

export default ErrorLayout;
