import tw from 'twin.macro';
import 'styled-components/macro';

export const BreadcrumbsArea = tw.div`
    flex
    flex-row
    justify-between
    mt-3
    mb-3
`;

export const Container = tw.div`flex justify-start flex-row mb-2`;

export const Breadcrumb = tw.div`
    flex
    items-center
    ml-2
    pl-2
    border-solid
    border-t-0
    border-r-0
    border-b-0
    border-l
    border-[#787878]
    h-6
`;
export const BreadcrumbActive = tw.div`
    flex
    items-center
    cursor-pointer
    text-blue1
    ml-2
    pl-2
    border-solid
    border-t-0
    border-r-0
    border-b-0
    border-l
    border-[#787878]
    h-6
`;
export const FirstBreadcrumb = tw.div`
    flex
    items-center
    h-6
`;
export const FirstBreadcrumbActive = tw.div`
    flex
    items-center
    cursor-pointer
    h-6
    text-blue1
`;

export const BackLink = tw.div`text-blue1 float-right cursor-pointer`;
