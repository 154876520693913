// noinspection com.haulmont.rcb.ArrayToJSXMapInspection

import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
const DocumentPage = lazy(() => import('../LostBaggageCase/LostBaggageCase'));
const Document = lazy(() => import('../Document'));
const StartPage = lazy(() => import('components/StartPage/StartPage'));

const MainRoutes = () => {
    return (
        <Routes>
            {/*<Route exact path="/case/:caseId" element={<DocumentPage />} />*/}
            <Route exact path="*" element={<Document />} />
            <Route element={<Navigate to="/Errors/404" />} />
        </Routes>
    );
};
export default MainRoutes;
