import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery, patchBaseQuery, postBaseQuery, setupBaseQuery } from '../../reducers/reducerService';

const ENDPOINT = 'messages';
export const messagesApiService = createApi({
    reducerPath: 'messagesApiService',
    baseQuery: setupBaseQuery(process.env.REACT_APP_MESSAGING),
    endpoints: (builder) => ({
        getMessageList: builder.query({
            query: ({ skip, searchTerm, groups, showHidden }) => {
                const searchTermParam = searchTerm ? '&searchTerm=' + searchTerm : '';
                const groupsParam = groups ? groups.map((x) => `&groups=${x}`).join('') : '';
                const showHiddenParam = `&showHidden=${showHidden}`;
                return getBaseQuery(`${ENDPOINT}/all?skip=${skip}${searchTermParam}${groupsParam}${showHiddenParam}`);
            },
        }),
        getMessageListByCaseId: builder.query({
            query: (caseId) => {
                return getBaseQuery(`${ENDPOINT}/case/${caseId}`);
            },
        }),
        getMessageThreadById: builder.mutation({
            query: (id) => getBaseQuery(`${ENDPOINT}/${id}/thread`),
        }),
        replyMessageById: builder.mutation({
            query: ({ id, body }) => postBaseQuery(body, `${ENDPOINT}/${id}/reply`),
        }),
        sendNewMessage: builder.mutation({
            query: (body) => postBaseQuery(body, `${ENDPOINT}/organizations/send`),
        }),
        sendNewMessageFromCase: builder.mutation({
            query: (body) => postBaseQuery(body, `${ENDPOINT}/case/organizations`),
        }),
        sendSupportMessage: builder.mutation({
            query: ({ message, caseId }) => postBaseQuery({ messageText: message, caseId }, `${ENDPOINT}/support`),
        }),
        callOrganizationChannel: builder.mutation({
            query: () => postBaseQuery({}, `${ENDPOINT}/organization/signal`),
        }),
        setVisible: builder.mutation({
            query: (id) => patchBaseQuery({}, `${ENDPOINT}/${id}/show`),
        }),
        setHidden: builder.mutation({
            query: (id) => patchBaseQuery({}, `${ENDPOINT}/${id}/hide`),
        }),
        checkHiddenMessagesExistence: builder.query({
            query: () => getBaseQuery(`${ENDPOINT}/check-hidden-messages-existence`),
        }),
    }),
});
const {
    useLazyGetMessageListQuery,
    useLazyGetMessageListByCaseIdQuery,
    useGetMessageThreadByIdMutation,
    useReplyMessageByIdMutation,
    useSendNewMessageMutation,
    useSendNewMessageFromCaseMutation,
    useSendSupportMessageMutation,
    useCallOrganizationChannelMutation,
    useSetVisibleMutation,
    useSetHiddenMutation,
    useCheckHiddenMessagesExistenceQuery,
} = messagesApiService;
export const MessagesApiMethods = {
    useLazyGetMessageListQuery,
    useLazyGetMessageListByCaseIdQuery,
    useGetMessageThreadByIdMutation,
    useReplyMessageByIdMutation,
    useSendNewMessageMutation,
    useSendNewMessageFromCaseMutation,
    useSendSupportMessageMutation,
    useCallOrganizationChannelMutation,
    useSetVisibleMutation,
    useSetHiddenMutation,
    useCheckHiddenMessagesExistenceQuery,
};
