import 'twin.macro';
import 'styled-components/macro';
import './MainHeader.less';
import LanguageSelector from './LanguageSelector';
import { ReactComponent as Arrow } from '../../assets/img/arrow-left.svg';
import { useSelector } from 'react-redux';
import {
    BackLink,
    Breadcrumb,
    BreadcrumbActive,
    BreadcrumbsArea,
    Container,
    FirstBreadcrumb,
    FirstBreadcrumbActive,
} from './headerStyles';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../constants/constants';
import {IsNullOrUndefined} from "lib/utils";
import { languageAtom } from 'components/Common/CommonControls/atoms';
import { ReactComponent as MenuLogoRu } from '../../assets/img/menu-logo-ru.svg';
import { ReactComponent as MenuLogoEn } from '../../assets/img/menu-logo-en.svg';
import { useRecoilState } from "recoil";

const MainHeader = () => {
    const header = useSelector(({ header }) => header);
    const navigate = useNavigate();
    const language = useRecoilState(languageAtom);
    const ru = language[0] === 'ru';

    return (
        <div>
            <div tw="flex justify-between pt-7">
                {ru ? (
                        <MenuLogoRu style={{ width: 195, height: 68 }} />
                ) : (
                        <MenuLogoEn style={{ width: 195, height: 68 }} />
                )}
                <div tw="Label_28">{header?.title}</div>
                <div tw="flex items-center">
                    <LanguageSelector />
                </div>
            </div>
           
        </div>
    );
};
export default MainHeader;
