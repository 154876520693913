import { RecoilRoot } from 'recoil';
import { Provider } from 'react-redux';
import store from './middleware/store';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { FallBackMessage } from './FallBackMessage';
import { AuthProvider } from "./middleware/services/AuthContext";

const AppRoot = () => {
    return (
        <RecoilRoot>
            <Provider store={store}>
               <AuthProvider>
                   <Suspense fallback={FallBackMessage()}>
                       <BrowserRouter>
                           <App />
                       </BrowserRouter>
                   </Suspense>
               </AuthProvider> 
            </Provider>
        </RecoilRoot>
    );
};

export default AppRoot;
