import { useEffect } from 'react';
import { CustomProvider } from 'rsuite';
import ruRu from 'rsuite/locales/ru_RU';
import enUS from 'rsuite/locales/en_US';
import { useTranslation } from 'react-i18next';
import { Container, Content, Header } from 'rsuite';
import './mainLayout.less';
import MainRoutes from '../Routes/mainRoutes';
import { AuthProvider } from "../../middleware/services/AuthContext";
import MainHeader from "../MainHeader";

const locales = { en: enUS, ru: ruRu };

const MainLayout = () => {
    const { i18n } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
            <CustomProvider locale={locales[i18n.resolvedLanguage]}>
                
                <Container className={'mainContainer'}>
                    <Header className={'mainHeader'}>
                        <MainHeader />
                    </Header>
                    <Content className={'mainContent'}>
                        <MainRoutes />
                    </Content>
                </Container>
            </CustomProvider>
    );
};

export default MainLayout;
