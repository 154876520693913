import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectPicker } from 'rsuite';
import { useSetRecoilState } from 'recoil';
import { ReactComponent as ArrowDown } from '../../../assets/img/arrowDown.svg';
import { languageAtom } from '../../Common/CommonControls/atoms';
import './LanguageSelector.less';
const languages = [
    { label: 'РУС', value: 'ru' },
    { label: 'ENG', value: 'en' },
];
const LanguageSelector = () => {
    const { i18n } = useTranslation(); // probably will add namespace!
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.resolvedLanguage);
    const setLanguage = useSetRecoilState(languageAtom);
    // function for switching languages
    // It is possible we will move this function else where
    const changeLanguage = (lng) => {
        setSelectedLanguage(lng);
        i18n.changeLanguage(lng);
        // setLanguage(lng);
    };

    useEffect(() => {
        setLanguage(i18n.language);
    }, [i18n.language]);

    return (
        <div className="language-selector">
            <SelectPicker
                menuClassName="language-selector-dropdown"
                data={languages}
                onChange={changeLanguage}
                value={selectedLanguage}
                size="sm"
                searchable={false}
                cleanable={false}
                caretAs={ArrowDown}
            />
        </div>
    );
};

export default LanguageSelector;
