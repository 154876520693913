import { createApi } from '@reduxjs/toolkit/query/react';
import { deleteBaseQuery, getBaseQuery, patchBaseQuery, postBaseQuery, setupBaseQuery } from './reducerService';

export const documentsLinksApi = createApi({
    reducerPath: 'documentsLinksApi',
    baseQuery: setupBaseQuery(`${process.env.REACT_APP_EXTERNAL_MANAGEMENT}`),
    tagTypes: ['DocumentsLinks'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getLinkedDocuments: builder.query({
            query: ({ documentId }) => getBaseQuery(`links/${documentId}`)
        }),
    }),
});
export const {
    useLazyGetLinkedDocumentsQuery,
} = documentsLinksApi;
