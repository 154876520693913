// if you are adding new type, please add it to the backend side too
// path: ..\Sources\Core\Domain\Enumerations\Messaging
export const MessageTypes = {
    LostBaggageCase: 1,
    MatchedBaggageFound: 2,
    UnclaimedBaggage: 3,
    ForwardedBaggage: 4,
    MessageForUser: 5,
    MessageForCaseRequester: 6,
    MessageForBaggageOwner: 7,
    ReplyMessage: 8,
    CaseDeletedMessage: 9,
    SupportRequestMessage: 10,
    BaggageDeliveryRequestMessage: 11,
    BaggageDeliveryMessage: 12,
    BaggageDeliveryWithoutCaseMessage: 13,
    BaggageDeliveryRequestDeclinedMessage: 14,
    BaggageDeliveryRequestConfirmedMessage: 15,
    LostCaseConversation: 16,
    UnclaimedBaggageConversation: 17,
    DocumentsSoonToBeArchived: 18,
    DamagedBaggageConversation: 19,
    QuickDelivery: 20,
    QuickDeliveryIsInDelivery: 21,
    QuickDeliveryIsDelivered: 22,
    QuickDeliveryConversation: 23,
    LostThingConversation: 24,
    FoundThingConversation: 25,
    BsmRushDelivery: 26,
    ComplexMatching: 27,
    AttachmentVersion: 28
};
