import { createSlice } from '@reduxjs/toolkit';
import { messagesInitialState } from './state';
import deepClone from '../../../lib/deepClone';
import { format } from 'date-fns';
import { MessageGroups } from '../../models';
import isNil from 'lodash/isNil';

export const MESSAGES_FEATURE_NAME = 'Messages';
const slice = createSlice({
    name: MESSAGES_FEATURE_NAME,
    initialState: messagesInitialState,
    reducers: {
        setInheritanceMode: (state, action) => ({
            ...state,
            inheritanceMode: action.payload,
        }),
        clearState: (state) => ({
            ...messagesInitialState,
            groups: state.groups,
            allMessagesCount: state.allMessagesCount,
        }),
        setMessageMode: (state, action) => ({ ...state, messageMode: action.payload }),
        setMessageId: (state, action) => ({ ...state, messageId: action.payload, threadMessages: [] }),
        setSkip: (state, action) => ({ ...state, skip: action.payload }),
        setIsLatest: (state, action) => ({ ...state, isLatest: action.payload }),
        setNeedData: (state, action) => ({ ...state, needData: action.payload }),
        setSearchTerm: (state, action) => ({
            ...state,
            searchTerm: action.payload,
        }),
        searchByTerm: (state) => ({
            ...state,
            skip: 0,
            isLatest: false,
            messageList: [],
            needData: true,
        }),
        updateMessageList: (state, action) => ({
            ...state,
            messageList: [...state.messageList, ...action.payload],
            messageListIsLoading: false,
            needData: false,
        }),
        setMessageList: (state, action) => ({
            ...state,
            messageList: action.payload,
            messageListIsLoading: false,
            needData: false,
        }),
        setGroups: (state, action) => ({ ...state, groups: action.payload }),
        clearMessageList: (state) => ({
            ...state,
            messageList: [],
            messageId: null,
            threadMessages: [],
        }),
        setMessageListLoading: (state) => ({ ...state, messageListIsLoading: true }),
        setThreadMessages: (state, action) => ({ ...state, threadMessages: action.payload }),
        setRestrictedOrganization: (state, action) => ({ ...state, restrictedOrganization: action.payload }),
        addThreadMessage: (state, action) => {
            const { payload } = action;
            const reply = payload;
            const date = format(new Date(reply.createdAt), 'dd.MM.yyyy');
            const threadMessages = deepClone(state.threadMessages);
            const existingArray = threadMessages.find((x) => x.date === date);
            const isExisted = existingArray != null;
            if (isExisted) {
                existingArray.replies = [reply, ...existingArray.replies];
            }
            return {
                ...state,
                threadMessages: isExisted ? threadMessages : [{ date, replies: [reply] }, ...threadMessages],
            };
        },
        setMessageToRead: (state, action) => {
            const messageList = [...state.messageList];
            const index = messageList.findIndex((x) => x.id === action.payload);
            messageList[index] = { ...messageList[index], isRead: true, numberOfUnreadReplies: 0 };
            return {
                ...state,
                messageList,
            };
        },
        updateActiveCardMessage: (state, action) => {
            const messageList = [...state.messageList];
            const id = state.messageId;
            const index = messageList.findIndex((x) => x.id === id);
            messageList[index] = { ...messageList[index], messageText: action.payload };
            if (index !== 0) {
                messageList.sort((x, y) => (x.id === id ? -1 : y.id === id ? 1 : 0));
            }
            return {
                ...state,
                messageList,
            };
        },
        addOrRemoveGroup: (state, action) => {
            const newGroups = deepClone(state.selectedGroups);
            const item = newGroups.find((x) => x === action.payload);
            if (isNil(item)) {
                newGroups.push(action.payload);
            } else {
                const ind = newGroups.indexOf(item);
                newGroups.splice(ind, 1);
            }
            if (newGroups.length === 0) {
                newGroups.push(MessageGroups.All);
            }
            return {
                ...state,
                skip: 0,
                searchTerm: undefined,
                messageList: null,
                messageListIsLoading: true,
                selectedGroups: newGroups,
            };
        },
        setActiveGroup: (state, action) => {
            return {
                ...state,
                skip: 0,
                searchTerm: undefined,
                messageList: null,
                messageListIsLoading: true,
                selectedGroups: [action.payload],
            };
        },
        setAllMessagesCount: (state, action) => ({ ...state, allMessagesCount: action.payload }),
        setIsMaintainable: (state, action) => ({ ...state, isMaintainable: action.payload }),
        setIsHiddenExist: (state, action) => ({ ...state, isHiddenExist: action.payload }),
        setShowHidden: (state, action) => ({ ...state, showHidden: action.payload }),
        setMessageHiddenOrVisible: (state, action) => {
            const messages = deepClone(state.messageList);
            const message = messages.find((x) => x.id === action.payload);
            const index = messages.indexOf(message);
            messages.splice(index, 1);
            const showHidden = state.showHidden;
            const isHiddenExist = state.isHiddenExist;
            const isMessageListEmpty = messages.length === 0;
            return {
                ...state,
                isHiddenExist: showHidden === false ? true : isMessageListEmpty ? false : isHiddenExist,
                showHidden: isMessageListEmpty ? !showHidden : showHidden,
                messageList: messages,
            };
        },
    },
});
export const MessagesActions = slice.actions;
export const MessagesReducer = slice.reducer;
