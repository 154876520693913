// if you are adding new group, please add it to the backend side too
// path: ..\Sources\Core\Domain\Enumerations\Messaging
export const MessageGroups = {
    All: 1,
    Forward: 2,
    Documents: 3,
    Dialogs: 4,
    Notifications: 5,
    Support: 6,
};
