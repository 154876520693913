import { createSlice } from '@reduxjs/toolkit';
import { supplementaryInitialState } from './state';

export const SUPPLEMENTARY_FEATURE_NAME = 'Supplementary';
const slice = createSlice({
    name: SUPPLEMENTARY_FEATURE_NAME,
    initialState: supplementaryInitialState,
    reducers: {
        setMessageTypes: (state, action) => ({
            ...state,
            messageTypes: action.payload,
        }),
    },
});
export const SupplementaryActions = slice.actions;
export const SupplementaryReducer = slice.reducer;
