import 'twin.macro';
import 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Loader } from 'rsuite';

export default function Loading({ children = null, size = 'lg', ...props }) {
    const { t } = useTranslation('translation');

    return (
            <div tw="absolute inset-0 flex justify-center items-center" {...props}>
                <Loader content={children || t('loading')} size={size} />
            </div>
    );
}
